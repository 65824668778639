<template>
  <div class="register_container">
    <div class="header">
      <div class="picker">
        <van-field readonly clickable label="选择就诊人" :value="patName" @click="patNamePickerShow = true"
          right-icon="arrow-down" />
        <van-popup v-model="patNamePickerShow" round position="bottom">
          <van-picker show-toolbar :columns="patNameList" @cancel="patNamePickerShow = false" @confirm="patNameConfirm" />
        </van-popup>
      </div>
      <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">温馨提示：默认查询三个月内的订单记录</van-notice-bar>
      <div class="picker">
        <van-field readonly clickable label="选择订单日期" :value="date" @click="datePickerShow = true" right-icon="arrow-down"
          class="picker" />
        <van-popup v-model="datePickerShow" round position="bottom">
          <van-datetime-picker v-model="currentDate" type="date" :min-date="minDate" :max-date="maxDate"
            :formatter="formatter" @confirm="dateConfirm" @cancel="dateCancel" />
        </van-popup>
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="item in orderList" :key="item.appointmentId">
        <!-- 倒计时 -->
        <van-count-down :time="item.appointmentExpire - nowExpire" format="支付剩余时间：mm 分 ss 秒"
          v-if="(!item.orderExpire && item.appointmentExpire - nowExpire > 0)" @finish="finish" />
        <van-count-down :time="item.orderExpire - nowExpire" format="支付剩余时间：mm 分 ss 秒"
          v-if="(item.orderExpire && item.orderExpire - nowExpire > 0) && item.isActive !== 0" @finish="finish" />
        <div class="top">
          <van-image class="avatar" width="60" :src="'http://tssywx.tssrmyy.com/' + item.doctor_code + '.jpg'"
            fit="contain" />
          <div class="info">
            <div>科室：{{ item.deptName }}</div>
            <div>医生：{{ item.doctorName }}</div>
            <div>就诊人：{{ item.name }}</div>
            <div>就诊时间：{{ item.scheduleDate }} {{ item.beginTime }}~{{ item.endTime }}</div>
            <div class="register-btn">
              <van-button :disabled="disabled" type="danger" v-if="item.isPay == 0"
                @click="unlockRegBtn(item.appointmentId)">取&nbsp;消</van-button>
              <van-button :disabled="disabled" type="primary" style="margin-left: 70px" v-if="item.isPay == 0"
                @click="unifiedOrderBtn(item.appointmentId, item.name, item.orderNum, item.recordType, item.regFee, item.outTradeNo, item.deptName, item.doctorName, item.scheduleDate, item.beginTime, item.endTime, item.orderRecordId, item.remark)">支&nbsp;付</van-button>
            </div>
          </div>
          <van-tag type="success" size="large" plain v-if="item.isPay === 6">已预约</van-tag>

          <van-tag type="success" size="large" plain v-if="item.arriveFlag === 1">已签到</van-tag>
          <van-tag type="warning" size="large" plain v-if="item.isPay === 4">已超时</van-tag>
          <van-tag type="warning" size="large" plain v-if="item.isPay === 5">退款中</van-tag>
          <van-tag type="primary" size="large" plain v-if="item.isPay === 3">已取消</van-tag>
          <van-tag type="success" size="large" plain v-if="item.isPay === 1">已支付</van-tag>
          <van-tag type="danger" size="large" plain v-if="item.isPay === 2">已退款</van-tag>

        </div>
        <div class="bottom">
          <!-- <div class="cost">诊查费：{{item.regFee}}元</div> -->
          <!-- 锁定号源 -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Register",
  data () {
    return {
      patName: "",
      patNamePickerShow: false,
      patNameList: [],
      patNameArray: [],
      patNameIndex: 0,
      patCardNo: "",
      minDate: new Date(1800, 0, 1),
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      currentDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      date: "",
      datePickerShow: false,
      end: "",
      orderList: [],
      nowExpire: 0,
      deptName: "",
      doctorName: "",
      name: "",
      timeSlot: "",
      beginTime: "",
      endTime: "",
      regFee: "",
      position: "",
      payData: {},
      disabled: false
    };
  },
  created () {
    this.patCardNo = this.$route.query.patCardNo;
    this.date = this.formatTime(-90);
    this.end = this.formatTime(0);
    this.getMembers();
  },
  methods: {
    unifiedOrderBtn (
      appointmentId,
      name,
      orderNum,
      recordType,
      regFee,
      outTradeNo,
      deptName,
      doctorName,
      timeSlot,
      beginTime,
      endTime,
      orderRecordId,
      remark
    ) {
      this.deptName = deptName;
      this.doctorName = doctorName;
      this.name = name;
      this.timeSlot = timeSlot;
      this.beginTime = beginTime;
      this.endTime = endTime;
      this.regFee = regFee;
      this.position = remark;
      this.disabled = true
      this.unifiedOrder(
        appointmentId,
        name,
        orderNum,
        recordType,
        regFee,
        outTradeNo,
        orderRecordId
      );
    },
    //获取所有建档人
    async getMembers () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
      });
      const { data: res } = await formPost(
        "/wxapp/userBindRecord/userBindRecord/getAllUseInfo",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.patNameArray = res.data;
        for (let i = 0; i < this.patNameArray.length; i++) {
          this.patNameList.push(res.data[i].name + '(' + res.data[i].pat_card_no + ')');
        }
        if (this.patCardNo === undefined) {
          this.patNameIndex = 0;
        } else {
          this.patNameIndex = this.patNameArray.findIndex(item => {
            return item.pat_card_no === this.patCardNo;
          });
        }
        this.patName = this.patNameList[this.patNameIndex];
        this.getOrderList();
      } else {
        this.$toast.fail(res.msg);
        setTimeout(() => {
          this.$router.push({
            path: "/file"
          });
        }, 2000);
      }
    },
    //获取预约记录
    async getOrderList () {
      this.nowExpire = new Date().getTime();
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        startTime: this.date,
        endTime: this.end,
        recordType: 1,
        patCardNo: this.patNameArray[this.patNameIndex].pat_card_no
      });
      const { data: res } = await formPost(
        "/wxapp/appointmentRecord/record/recordList",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        let arr = res.data;
        let orderList = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].isPay === 0) {
            orderList.push(arr[i]);
          }
        }
        if (orderList.length > 0) {
          this.orderList = orderList;
        } else {
          this.orderList = [];
          this.$toast.fail("查无记录");
        }
      } else {
        this.orderList = [];
        this.$toast.fail(res.msg);
      }
    },
    //调起微信支付
    pay () {
      let that = this;
      function onBridgeReady () {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: "wxcdae494f7e9422e7",
            timeStamp: that.payData.timeStamp,
            nonceStr: that.payData.nonceStr,
            package: that.payData.package,
            signType: that.payData.signType,
            paySign: that.payData.paySign
          },
          function (res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              that.$router.push({
                path: "/register/pay",
                query: {
                  name: that.patName,
                  deptName: that.deptName,
                  doctorName: that.doctorName,
                  timeSlot: that.timeSlot,
                  beginTime: that.beginTime,
                  endTime: that.endTime,
                  regFee: that.regFee,
                  outTradeNo: that.payData.outTradeNo,
                  patCardNo: that.patCardNo,
                  position: that.position
                }
              });
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              that.$toast("支付未完成");
              setTimeout(() => {
                that.getOrderList();
              }, 2000);
            } else {
              that.$toast(res.err_msg);
            }
          }
        );
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    },
    //微信下单(锁定号源)
    async order (
      appointmentId,
      orderNum,
      recordType,
      orderRecordId,
      name,
      regFee
    ) {
      console.log(appointmentId,
        orderNum,
        recordType,
        name,
        regFee);
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/pay/wxpay/unifiedOrder", {
        appointmentId: appointmentId,
        name: name,
        openId: window.localStorage.getItem("openId"),
        orderNum: orderNum,
        recordType: recordType,
        regFee: regFee,
        orderRecordId: orderRecordId
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.payData = res.data;
        this.pay();
      } else {
        this.$toast(res.msg);
      }
    },
    //释放号源
    async unlockReg (appointmentId) {
      console.log(appointmentId);
      this.$toast.loading({
        duration: 0,
        message: "取消中",
        forbidClick: true
      });
      let data = qs.stringify({
        appointmentId: appointmentId
      });
      const { data: res } = await formPost(
        "/wxapp/appointmentRecord/record/unlockReg",
        data
      );
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.getOrderList();
        }, 2000);
      } else {
        this.$toast(res.msg);
      }
    },
    //微信下单(用户取消支付)
    async unifiedOrder (
      appointmentId,
      name,
      orderNum,
      recordType,
      regFee,
      outTradeNo,
      orderRecordId
    ) {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/pay/wxpay/unifiedOrder", {
        openId: window.localStorage.getItem("openId"),
        appointmentId: appointmentId,
        name: name,
        orderNum: orderNum,
        recordType: recordType,
        regFee: regFee,
        outTradeNo: outTradeNo,
        orderRecordId: orderRecordId
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.payData = res.data;
        this.pay();
      } else {
        this.$toast(res.msg);
      }
    },
    //取消订单
    async closeOrder (appointmentId, outTradeNo, recordType) {
      this.$toast.loading({
        duration: 0,
        message: "取消中",
        forbidClick: true
      });
      let data = qs.stringify({
        openId: window.localStorage.getItem("openId"),
        appointmentId: appointmentId,
        outTradeNo: outTradeNo,
        recordType: recordType
      });
      const { data: res } = await formPost("/wxapp/pay/wxpay/closeOrder", data);
      this.$toast.clear();
      if (res.code === 0) {
        this.$toast.success(res.msg);
        setTimeout(() => {
          this.getOrderList();
        }, 2000);
      } else {
        this.$toast(res.msg);
      }
    },
    finish () {
      this.getOrderList();
    },
    patNameConfirm (value) {
      this.patName = value;
      this.patNameIndex = this.patNameList.findIndex(item => {
        return item === value;
      });
      this.patNamePickerShow = false;
      this.getOrderList();
    },
    formatter (type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return `${val}日`;
    },
    formatTime (num) {
      let now = new Date();
      let nowTime = now.getTime();
      let oneDayTime = 24 * 60 * 60 * 1000;
      let ShowTime = nowTime + num * oneDayTime;
      let currentShowTime = nowTime + -6 * oneDayTime;
      let myDate = new Date(ShowTime);
      let currentDate = new Date(currentShowTime);
      let y = myDate.getFullYear(); //年
      let m =
        myDate.getMonth() + 1 < 10
          ? "0" + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1; //月
      let d = myDate.getDate() < 10 ? "0" + myDate.getDate() : myDate.getDate(); //日
      this.currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      return y + "-" + m + "-" + d;
    },
    dateFormat (dateData) {
      let date = new Date(dateData);
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let time = y + "-" + m + "-" + d;
      let today = new Date().getFullYear();
      return time;
    },
    dateConfirm (value) {
      this.date = this.dateFormat(value);
      this.datePickerShow = false;
      this.getOrderList();
    },
    dateCancel () {
      this.datePickerShow = false;
    },
    orderBtn (
      appointmentId,
      name,
      orderNum,
      recordType,
      regFee,
      deptName,
      doctorName,
      timeSlot,
      beginTime,
      endTime,
      orderRecordId,
      remark
    ) {
      this.deptName = deptName;
      this.doctorName = doctorName;
      this.name = name;
      this.timeSlot = timeSlot;
      this.beginTime = beginTime;
      this.endTime = endTime;
      this.regFee = regFee;
      this.position = remark;
      this.order(
        appointmentId,
        orderNum,
        recordType,
        orderRecordId,
        name,
        regFee
      );
    },
    unlockRegBtn (appointmentId) {
      this.disabled = true
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定取消该预约吗？"
        })
        .then(() => {
          this.unlockReg(appointmentId);
        })
        .catch(() => {
          this.disabled = false
        });
    },

    closeOrderBtn (appointmentId, outTradeNo, recordType) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定取消该预约吗？"
        })
        .then(() => {
          this.closeOrder(appointmentId, outTradeNo, recordType);
        })
        .catch(() => { });
    }
  }
};
</script>

<style scope>
.register_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid rgb(201, 191, 191);
}

.register_container .header .picker .van-cell {
  padding: 30px;
}

.register_container .header .picker .van-cell::after {
  border: 0;
}

.register_container .header .picker .van-field__label {
  text-align: left;
  font-size: 40px;
}

.register_container .header .picker .van-field__control {
  text-align: right;
  margin-right: -10px;
  font-size: 40px;
}

.register_container .header .picker .van-icon {
  font-size: 40px;
}

.register_container .header .picker .van-picker-column {
  font-size: 40px;
}

.register_container .header .picker .van-picker__toolbar {
  height: 150px;
}

.register_container .header .picker .van-picker__cancel,
.register_container .header .picker .van-picker__confirm {
  font-size: 40px;
  padding: 40px;
}

.register_container .header .van-notice-bar__content {
  font-size: 32px;
}

.register_container .header .van-notice-bar__left-icon {
  font-size: 32px;
}

.register_container .list {
  padding-bottom: 60px;
}

.register_container .list .item {
  border-bottom: 1px solid #f5f5f5;
}

.register_container .list .item .top {
  position: relative;
  display: flex;
  font-size: 32px;
  align-items: center;
  padding: 20px 30px;
}

.register_container .list .item .top .avatar {
  flex: 1;
}

.register_container .list .item .top .info {
  position: relative;
  flex: 4;
  margin-left: 20px;
  line-height: 70px;
}

.register_container .list .item .top .van-tag {
  position: absolute;
  top: 60px;
  right: 30px;
}

.register_container .list .item .van-count-down {
  color: red;
  font-size: 34px;
  padding: 0 30px;
  padding-top: 30px;
}

.register_container .list .item .bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 20px 30px;
}

.register_container .list .item .bottom .cost {
  width: 45%;
  font-size: 40px;
}

.register_container .list .item .bottom .register-btn {
  width: 55%;
  display: flex;
}

.register_container .list .item .bottom .register-btn .van-button {
  font-size: 34px;
}
</style>